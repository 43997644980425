import { type KiliLayer, type KiliRelationLayer } from '../KiliLeafletLayers/types';

type LayerListener = (layers: KiliLayer[] | undefined) => void;
class StoreLayers {
  private readonly subscriptions: Map<string, LayerListener[]>;

  private readonly layers: Map<string, KiliLayer[]>;

  constructor() {
    this.layers = new Map<string, KiliLayer[]>();
    this.subscriptions = new Map<string, LayerListener[]>();
  }

  delete(key: string) {
    this.layers.delete(key);
    this.subscriptions.get(key)?.forEach(listener => listener(undefined));
  }

  get(key: string) {
    return this.layers.get(key);
  }

  values() {
    return this.layers.values();
  }

  set(key: string, layers: KiliLayer[]) {
    this.layers.set(key, layers);

    const newLayersForKey = this.layers.get(key);
    if (newLayersForKey) {
      this.subscriptions.get(key)?.forEach(listener => listener(newLayersForKey));
    }
  }

  clear() {
    this.layers.clear();
    this.subscriptions.forEach(listeners => {
      listeners.forEach(listener => listener(undefined));
    });
  }

  subscribe(key: string, listener: LayerListener) {
    const { subscriptions } = this;

    if (!subscriptions.has(key)) {
      subscriptions.set(key, []);
    }

    const currentLayers = this.layers.get(key);
    if (currentLayers) {
      listener(currentLayers);
    }

    subscriptions.get(key)?.push(listener);

    // Unsubscribe
    return () => {
      const allSubscriptionsForKey = subscriptions.get(key);

      if (allSubscriptionsForKey !== undefined) {
        allSubscriptionsForKey.splice(allSubscriptionsForKey.indexOf(listener), 1);
      }
    };
  }
}

const layersByMid = new StoreLayers();

export const layersRelationByMid = new Map<string, KiliRelationLayer>();

export const areaByMid = new Map<string, number>();

export default layersByMid;
