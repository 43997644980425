import { createStyles, type StyleRules, withStyles } from '@material-ui/core/styles';

import NotificationIcon from './NotificationIcon';

export const styles = (): StyleRules<'notificationIcon' | 'notificationIconOpen'> =>
  createStyles({
    notificationIcon: {
      '& svg': {
        height: 24,
        width: 24,
      },
      '&:hover': {
        background: 'var(--color-omega-accent-2)',
      },
      borderRadius: 'var(--br-4)',
      color: 'var(--color-omega-accent-6)',
      height: 32,
      padding: 0,
      width: 32,
    },
    notificationIconOpen: {
      '&:hover': {
        background: 'var(--color-omega-accent-3)',
      },
      background: 'var(--color-omega-accent-3)',
    },
  });

export default withStyles(styles)(NotificationIcon);
