export const DOC_BASE_URL = 'https://docs.kili-technology.com/docs';
export enum DocUrl {
  BestPracticesForOntologyDesign = '/best-practices-for-ontology-design',
  ConsensusFilter = '/consensus-overview',
  CreateOntologyWithAPI = '/customizing-the-interface-through-json-settings',
  ExportOptions = '/exporting-project-data',
  FilteringAssetsFromCloudStorage = '/filtering-assets-from-cloud-storage#prefix-filter',
  GettingStarted = '/getting-started-with-kili',
  Home = '/',
  HoneypotFilter = '/honeypot-overview',
  HumanmodelIouFilter = '/asset-lifecycle#inference',
  ImportCSVfile = '/adding-assets-to-project#required-csv-file-format',
  ImportRichText = '/importing-rich-text-assets',
  IntegrationAWS = '/creating-an-amazon-web-storage-integration',
  IntegrationAzure = '/creating-an-azure-blob-storage-integration',
  IntegrationCustomS3 = '/creating-an-s3-compatible-integration',
  IntegrationGCP = '/creating-a-gcp-cloud-storage-integration',
  InterfacesImageSegmentation = '/semantic-segmentation',
  InterfacesImageSegmentationInteractiveSegmentation = '/semantic-segmentation#interactive-segmentation',
  InterfacesVideoObjectDetection = '/labeling-video-assets',
  IntroductionToKiliTechnology = '/introduction-to-kili-technology',
  QualityHoneypot = '/honeypot-overview',
  QualityHoneypotSettingAssets = '/using-honeypot-for-quality-management',
  QualityInstructions = '/adding-instructions',
  QualityQuestion = '/asking-questions',
  QualityQuestionIssue = '/handling-questions-and-issues',
  UserRoleInOrganization = '/user-roles-in-organization',
  UserRoleInProject = '/user-roles-in-projects',
}

export const DOC_SDK_URL = 'https://python-sdk-docs.kili-technology.com';
export const SDKDocUrl = {
  ImportingAssetsAndMetadata: '/sdk/tutorials/importing_assets_and_metadata',
} as const;
