import { cx } from '@emotion/css';
import { HandClickingClass } from '@kili-technology/cursors';
import { Notif } from '@kili-technology/icons';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { type WithStyles } from '@material-ui/core/styles';
import React from 'react';

import { type styles } from './NotificationIcon.style';

type PropsType = {
  isBadgeInvisible: boolean;
  isOpen: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
} & WithStyles<typeof styles>;

const NotificationIcon = ({
  classes,
  onClick,
  isBadgeInvisible,
  isOpen,
}: PropsType): JSX.Element => (
  <IconButton
    className={cx(HandClickingClass, classes.notificationIcon, {
      [classes.notificationIconOpen]: isOpen,
    })}
    color="inherit"
    data-cy="notifications-button"
    data-testid="notifications-button"
    disableRipple
    onClick={onClick}
    onKeyDown={e => e.preventDefault()}
  >
    <Badge color="error" invisible={isBadgeInvisible} overlap="rectangular" variant="dot">
      <Notif />
    </Badge>
  </IconButton>
);

export default NotificationIcon;
