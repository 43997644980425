import { cx } from '@emotion/css';
import { Check, Suspend } from '@kili-technology/icons';
import { Box, CircularProgress, IconButton, MenuItem } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { type WithStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/GetApp';

import { type styles } from './NotificationRow.style';

import { NotificationStatus } from '../../../../__generated__/globalTypes';
import { downloadBucketSignedExportUrl } from '../../../../services/assets/download';

type PropsType = {
  message: string;
  progress: number | null;
  status: string;
  url: string;
} & WithStyles<typeof styles>;

const NotificationRow = ({ classes, message, status, progress, url }: PropsType): JSX.Element => {
  return (
    <MenuItem className={classes.menuItem} data-cy="cypress-notification" disableRipple>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          {status === NotificationStatus.FAILURE && (
            <Suspend className={classes.failureIcon} data-cy="notification-failure" />
          )}
          {status === NotificationStatus.PENDING && (
            <CircularProgress data-cy="notification-pending" size={18} />
          )}
          {status === NotificationStatus.SUCCESS && (
            <Check className={classes.successIcon} data-cy="notification-success" />
          )}
        </div>
        <p className={cx(classes.message, 'menu')} data-cy="notification-message">
          {message}
        </p>
        {url && (
          <IconButton
            data-cy="download-button"
            data-testid="download-button"
            disableRipple
            onClick={() => downloadBucketSignedExportUrl(url)}
            size="small"
          >
            <DownloadIcon />
          </IconButton>
        )}
      </div>
      {status === NotificationStatus.PENDING && progress !== null && (
        <Box alignItems="center" display="flex" height={35} width="100%">
          <Box mr={1} width="100%">
            <LinearProgress className={classes.progress} value={progress} variant="determinate" />
          </Box>
          <Box minWidth={30}>
            <p className="body-2">{`${Math.round(progress)}%`}</p>
          </Box>
        </Box>
      )}
    </MenuItem>
  );
};

export default NotificationRow;
