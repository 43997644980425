import { createStyles, type StyleRules, type Theme, withStyles } from '@material-ui/core/styles';

import NotificationPopper from './NotificationPopper';

import { POPPER_NOTIFICATION_ZINDEX } from '../../../constants/zIndexes';

export const styles = (theme: Theme): StyleRules<'message' | 'paging' | 'paper' | 'popper'> =>
  createStyles({
    message: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'break-spaces',
    },
    paging: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 1.5),
    },
    paper: {
      '& > ul': {
        paddingBottom: 0,
      },
      borderRadius: 'var(--br-6)',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.14)',
      width: theme.spacing(50),
    },
    popper: {
      marginTop: 50,
      zIndex: POPPER_NOTIFICATION_ZINDEX,
    },
  });

export default withStyles(styles)(NotificationPopper);
