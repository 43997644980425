/* eslint-disable no-underscore-dangle */

import { OFREPWebProvider } from '@openfeature/ofrep-web-provider';
import { OpenFeature, InMemoryProvider } from '@openfeature/react-sdk';
import { useEffect } from 'react';

import { isUsingFlipt } from '@/config';

import { DEVELOPMENT_FLAGS } from './developmentFlags';

export const useOpenFeatureProvider = (
  email: string,
  userId: string,
  organizationId: string,
): void => {
  useEffect(() => {
    if (email && userId && organizationId) {
      OpenFeature.setContext({ email, organizationId, userId });

      if (!isUsingFlipt) {
        /* InMemoryProvider does not support typing for flags keys
        https://github.com/open-feature/js-sdk/issues/967
        */
        OpenFeature.setProvider(new InMemoryProvider(DEVELOPMENT_FLAGS as any));
      } else {
        const apiUrl = window._env_?.FEATURE_FLAG__API_URL;
        const apiNamespace = window._env_?.FEATURE_FLAG__API_NAMESPACE;

        OpenFeature.setProvider(
          new OFREPWebProvider({
            baseUrl: apiUrl,
            headers: [['x-flipt-namespace', apiNamespace]],
          }),
        );
      }
    }
  }, [email, organizationId, userId]);
};
