import { createStyles, type StyleRules, type Theme, withStyles } from '@material-ui/core/styles';

import NotificationRow from './NotificationRow';

export const styles = (
  theme: Theme,
): StyleRules<
  | 'container'
  | 'failureIcon'
  | 'iconContainer'
  | 'message'
  | 'menuItem'
  | 'progress'
  | 'successIcon'
> =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
    },
    failureIcon: {
      color: theme.palette.error.main,
      height: '100%',
      width: '100%',
    },
    iconContainer: {
      aspectRatio: '1 / 1',
      height: 30,
      marginRight: theme.spacing(1),
      padding: theme.spacing(0.5),
      width: 30,
    },
    menuItem: {
      alignItems: 'flex-start',
      borderBottom: `solid 1px var(--color-omega-accent-2)`,
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
    message: {
      margin: 0,
      overflow: 'hidden',
      paddingRight: theme.spacing(1),
      textOverflow: 'ellipsis',
      whiteSpace: 'break-spaces',
    },
    pendingIcon: {
      display: 'flex',
      margin: theme.spacing(2),
    },
    progress: {
      borderRadius: 10,
      margin: theme.spacing(0, 0.5),
    },
    successIcon: {
      color: 'var(--color-alpha-accent-9)',
      height: '100%',
      width: '100%',
    },
  });

export default withStyles(styles)(NotificationRow);
